<template>
  <div id="app">
    <div class="main-content">
      <header-app></header-app>
      <WebSite />
      <!-- <HomePage></HomePage> -->
    </div>
  </div>
</template>

<script>
import HeaderApp from './components/headerApp.vue'
// import HomePage from '@/views/home-page.vue'
import WebSite from './views/web-site.vue'

export default {
  name: 'App',
  components: {
    HeaderApp,
    WebSite
    // HomePage
  }
}
</script>

<style>
@import './assets/style/font.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  margin: 0;
}

.main-content {
  position: relative;
}
::-webkit-scrollbar {
    display:none;
    width:0;
}
@media screen and (max-width: 750px){
  html {
    font-size: 13.3333vw;
  }
}
html {
  line-height: 1.15;
  background-color: #fff;
}
</style>
